'use strict'

angular
  .module 'mundoAdmin.users'
  .config ($stateProvider) ->
    $stateProvider
      .state 'users',
        parent: 'admin'
        url: '/users'
        abstract: true
        views:
          '@admin':
            templateUrl: 'mundo-admin/users/views/users.tpl.html'

      .state 'users.tenants',
        url: '/tenants'
        views:
          'tenants@users':
            templateUrl: 'mundo-components/mundo-data-table/views/mundo-data-table.tpl.html'
            controller: 'DataTableCtrl'
            controllerAs: 'listCtrl'
            resolve:
              entityManager: (TenantManager) ->
                TenantManager
              pageTitle: ->
                'app.admin.pageTitles.users.tenants'
              pageDescription: ->
                'app.admin.descriptions.users.tenants'
              deleteBoxTitle: ->
                'app.admin.actions.users.deleteTenant'
              deleteBoxBody: ->
                'app.admin.actions.users.deleteTenant'
              AddBoxTitle: ->
                'app.admin.actions.users.addTenant'
              editBoxTitle: ->
                'app.admin.actions.users.editTenant'
              loadFilterParams: ->
                {}
      .state 'users.overview',
        url: '/overview'
        views:
          'users@users':
            templateUrl: 'mundo-components/mundo-data-table/views/mundo-data-table.tpl.html'
            controller: 'DataTableCtrl'
            controllerAs: 'listCtrl'
            resolve:
              entityManager: (TenantUserManager) ->
                TenantUserManager
              pageTitle: ->
                'app.admin.pageTitles.users.users'
              pageDescription: ->
                'app.admin.descriptions.users.users'
              deleteBoxTitle: ->
                'app.admin.actions.users.deleteUser'
              deleteBoxBody: ->
                'app.admin.actions.users.deleteUser'
              AddBoxTitle: ->
                'app.admin.actions.users.addUser'
              editBoxTitle: ->
                'app.admin.actions.users.editUser'
              loadFilterParams: ->
                {}
      .state 'users.groups',
        url: '/groups'
        views:
          'users_groups@users':
            templateUrl: 'mundo-components/mundo-data-table/views/mundo-data-table.tpl.html'
            controller: 'DataTableCtrl'
            controllerAs: 'listCtrl'
            resolve:
              entityManager: (UserGroupManager) ->
                UserGroupManager
              pageTitle: ->
                'app.admin.pageTitles.users.userGroups'
              pageDescription: ->
                'app.admin.descriptions.users.userGroups'
              deleteBoxTitle: ->
                'app.admin.actions.users.deleteUserGroup'
              deleteBoxBody: ->
                'app.admin.actions.users.deleteUserGroup'
              AddBoxTitle: ->
                'app.admin.actions.users.addUserGroup'
              editBoxTitle: ->
                'app.admin.actions.users.editUserGroup'
              loadFilterParams: ->
                {}
      .state 'users.roles',
        url: '/roles'
        views:
          'users_roles@users':
            templateUrl: 'mundo-components/mundo-data-table/views/mundo-data-table.tpl.html'
            controller: 'DataTableCtrl'
            controllerAs: 'listCtrl'
            resolve:
              entityManager: (UserRoleManager) ->
                UserRoleManager
              pageTitle: ->
                'app.admin.pageTitles.users.userRoles'
              pageDescription: ->
                'app.admin.descriptions.users.userRoles'
              deleteBoxTitle: ->
                'app.admin.actions.users.deleteUserRole'
              deleteBoxBody: ->
                'app.admin.actions.users.deleteUserRole'
              AddBoxTitle: ->
                'app.admin.actions.users.addUserRole'
              editBoxTitle: ->
                'app.admin.actions.users.editUserRole'
              loadFilterParams: ->
                {}
      # .state 'users.contexts',
      #   url: '/contexts'
      #   data:
      #     'selectedTab': 4
      #   views:
      #     'users_contexts@users':
      #       templateUrl: 'mundo-components/mundo-data-table/views/mundo-data-table.tpl.html'
      #       controller: 'DataTableCtrl'
      #       controllerAs: 'listCtrl'
      #       resolve:
      #         entityManager: (UserContextManager) ->
      #           UserContextManager
      #         pageTitle: ->
      #           'app.admin.pageTitles.users.userContexts'
      #         pageDescription: ->
      #           'app.admin.descriptions.users.userContexts'
      #         deleteBoxTitle: ->
      #           'app.admin.actions.users.deleteUserContext'
      #         deleteBoxBody: ->
      #           'app.admin.actions.users.deleteUserContext'
      #         AddBoxTitle: ->
      #           'app.admin.actions.users.addUserContext'
      #         editBoxTitle: ->
      #           'app.admin.actions.users.editUserContext'
      #         loadFilterParams: ->
      #           {
      #             'filter[isGroup]': 'isGroup,FALSE'
      #           }
      # .state 'users.contexts_groups',
      #   url: '/contexts_groups'
      #   data:
      #     'selectedTab': 5
      #   views:
      #     'users_contexts_groups@users':
      #       templateUrl: 'mundo-components/mundo-data-table/views/mundo-data-table.tpl.html'
      #       controller: 'DataTableCtrl'
      #       controllerAs: 'listCtrl'
      #       resolve:
      #         entityManager: (UserContextGroupManager) ->
      #           UserContextGroupManager
      #         pageTitle: ->
      #           'app.admin.pageTitles.users.userContextGroups'
      #         pageDescription: ->
      #           'app.admin.descriptions.users.userContextGroups'
      #         deleteBoxTitle: ->
      #           'app.admin.actions.users.deleteUserContextGroup'
      #         deleteBoxBody: ->
      #           'app.admin.actions.users.deleteUserContextGroup'
      #         AddBoxTitle: ->
      #           'app.admin.actions.users.addUserContextGroup'
      #         editBoxTitle: ->
      #           'app.admin.actions.users.editUserContextGroup'
      #         loadFilterParams: ->
      #           {}
